<template>
  <div class="game_rule">
    <div class="content">
      <div class="text_box">
        <div class="option">
          <div class="lottery" :class="gameRuleIndex==0?'checked':''" @click="changeRule(0)">
            七星彩
          </div>

          <div class="lottery" :class="gameRuleIndex==2?'checked':''" @click="changeRule(2)">
            香港六合彩
          </div>
          <div class="lottery" :class="gameRuleIndex==3?'checked':''" @click="changeRule(3)">
            排列三
          </div>
          <div class="lottery" :class="gameRuleIndex==5?'checked':''" @click="changeRule(5)">
            排列五
          </div>
          <div class="line"></div>
        </div>
        <article v-if="gameRuleIndex==0">
          <section class="active">
            <a @click="accordion">第一章 总则</a>
            <div class="content">
              <p>
                第一条 根据《彩票管理条例》、《彩票管理条例实施细则》、《彩票发行销售管理办法》等有关规定，制定本规则。
              </p>
              <p>
                第二条 中国体育彩票7星彩（以下简称7星彩）由国家体育总局体育彩票管理中心发行和组织销售。由各省、自治区、直辖市体育彩票销售机构（以下称各省体彩机构）在所辖区域内销售。
              </p>
              <p>
                第三条 7星彩采用计算机网络系统发行，在各省体彩机构设置的销售实体店销售，定期开奖。
              </p>
              <p>
                第四条 7星彩实行自愿购买，凡购票者均被视为同意并遵守本规则。
              </p>
              <p>
                第五条 不得向未成年人出售彩票或兑付奖金。
              </p>
            </div>
          </section>
          <section>
            <a @click="accordion">
              第二章 投注
            </a>
            <div class='content'>
              <p>
                第六条 7星彩投注是指从000000-999999中选取1个6位数字作为前六位、从0-14中选取1个数字作为最后一位，共同组成一注号码进行的投注。每注金额人民币2元。
              </p>
              <p>
                第七条 购买者可以进行复式投注。复式投注包括三种形式：
              </p>
              <p>
                (一)前六位复式：前六位中1位或多位上选取2个及以上数字，最后一位选取1个数字；
              </p>
              <p>
                (二)最后一位复式：前六位选取1个6位数字，最后一位选取2个及以上数字；
              </p>
              <p>
                (三)全复式：前六位1位或多位上选取2个及以上数字，最后一位选取2个及以上数字。
              </p>
              <p>
                第八条 购买者可对其选定的投注号码进行多倍投注，每张彩票中的单注号码多倍投注的倍数范围为2-99倍。单张彩票的投注金额最高不得超过20000元。
              </p>
              <p>
                第九条 7星彩按期销售，每周销售三期，期号以开奖日界定，按日历年度编排。
              </p>
              <p>
                第十条 购买者可在各省体彩机构设置的销售实体店投注。投注号码经投注机打印出兑奖凭证，交购买者保存，此兑奖凭证即为7星彩彩票。
              </p>
              <p>
                第十一条 购买者可选择机选号码投注、自选号码投注。机选号码投注是指由投注机随机产生投注号码进行投注，自选号码投注是指将购买者选定的号码输入投注机进行投注。
              </p>
            </div>
          </section>
          <section>
            <a @click="accordion">
              第三章 设奖
            </a>
            <div class='content'>
              <p>
                第十二条 7星彩按当期销售总额的50%、13%、37%分别计提彩票奖金、彩票发行费和彩票公益金。彩票奖金分为当期奖金和调节基金，其中，49%为当期奖金，1%为调节基金。
              </p>
              <p>
                第十三条 7星彩共设六个奖级，一、二等奖为浮动奖，三、四、五、六等奖为固定奖。各奖级和奖金规定如下
              </p>
              <p>
                一等奖：奖金总额为当期奖金额减去固定奖总额后的90%与奖池中累积的奖金之和，单注奖金按注均分，单注最高限额500万元。
              </p>
              <p>
                二等奖：奖金总额为当期奖金额减去固定奖总额后的10%，单注奖金按注均分，单注最高限额500万元。
              </p>
              <p>
                三等奖：单注奖金固定为3000元。
              </p>
              <p>
                四等奖：单注奖金固定为500元。
              </p>
              <p>
                五等奖：单注奖金固定为30元。
              </p>
              <p>
                六等奖：单注奖金固定为5元。
              </p>
              <p>
                第十四条
                7星彩设置调节基金。调节基金包括按销售总额1%的提取部分、浮动奖奖金按元取整后的余额、逾期未退票的票款。调节基金专项用于支付各种不可预见情况下的奖金支出风险、调节浮动奖奖金以及设立特别奖。若当期奖金额不足以支付固定奖总额时，不足部分从调节基金中支付。若调节基金不足时，用彩票兑奖周转金垫支。
              </p>
              <p>
                第十五条 7星彩设置奖池，奖池由未中出的浮动奖奖金和超出浮动奖单注奖金封顶限额部分的奖金组成。奖池与当期奖金中用于一等奖的部分及调节基金转入部分合并支付一等奖奖金。
              </p>
              <p>
                第十六条 当期开奖前，奖池资金累积超过3亿元（含）时，当期一等奖与二等奖奖金分配比例倒置，即一等奖分配奖金为当期奖金额减去固定奖总额后的10%，二等奖分配奖金为当期奖金额减去固定奖总额后的90%。
              </p>
              <p>
                第十七条
                一、二等奖按照该奖级实际中奖注数平均分配该奖级奖金。当上一奖级单注奖金低于下一奖级单注奖金的2倍且低于500万元时，上一奖级单注奖金补足至下一奖级单注奖金的2倍且不高于500万元。所需资金从调节基金中支付，若调节基金不足时，用彩票兑奖周转金垫支。
              </p>
              <p>
                第十八条 7星彩设置单期最大返奖总额,若当期应付奖金超出最大返奖总额，则各奖级单注奖金（含浮动奖单注最低奖金）相应调整。详情请见《中国体育彩票7星彩游戏风险控制办法》
              </p>
              <p>
                第十九条 在出现彩票兑奖周转金垫支情况下，当调节基金有资金滚入时优先偿还垫支的彩票兑奖周转金。
              </p>
            </div>
          </section>
          <section>
            <a @click="accordion">
              第四章 开奖
            </a>
            <div class='content'>
              <p>
                第二十条 7星彩每周二、五、日开奖。每期开奖时，在公证人员封存销售数据资料之后，并在其监督下从6组0-9中按顺序摇出6位数字作为前六位、从0-14中摇出1个数字作为最后一位，共同组成7星彩开奖号码。
              </p>
              <p>
                第二十一条 每期开奖后，体育彩票发行机构应向社会公布当期销售总额、开奖号码、各奖级中奖情况以及奖池资金余额等信息，并将开奖结果通知7星彩销售实体店。
              </p>

              <a @click="accordion">
                第五章 中奖
              </a>

              <p>
                第二十二条 7星彩根据投注号码与开奖号码相符情况确定相应中奖资格。具体规定如下：
              </p>
              <p>
                一等奖：投注号码的全部数字与开奖号码对应位置数字均相同，即中奖；
              </p>
              <p>
                二等奖：投注号码的前6位数字与开奖号码对应位置数字相同，即中奖；
              </p>
              <p>
                三等奖：投注号码前6位中的任意5个数字与开奖号码对应位置数字相同且最后一个数字与开奖号码对应位置数字相同，即中奖；
              </p>
              <p>
                四等奖：投注号码中任意5个数字与开奖号码对应位置数字相同，即中奖；
              </p>
              <p>
                五等奖：投注号码中任意4个数字与开奖号码对应位置数字相同，即中奖；
              </p>
              <p>
                六等奖：投注号码中任意3个数字与开奖号码对应位置数字相同，或者投注号码前6位中的任意1个数字与开奖号码对应位置数字相同且最后一个数字与开奖号码对应位置数字相同，或者仅最后一个数字与开奖号码对应位置数字相同，即中奖。
              </p>
              <p>
                第二十三条 当期每注投注号码只有一次中奖机会，各奖级奖金不能兼中兼得，另行设立的特别奖除外。
              </p>
            </div>
          </section>
          <section>
            <a @click="accordion">
              第六章 兑奖
            </a>
            <div class='content'>
              <p>
                第二十四条 7星彩兑奖当期有效。中奖者应当自开奖之日起60个自然日内，持中奖彩票到指定的地点兑奖。逾期未兑奖视为弃奖，弃奖奖金纳入彩票公益金。
              </p>
              <p>
                第二十五条 中奖彩票为兑奖唯一凭证，中奖彩票因玷污、损坏等原因不能正确识别的，不能兑奖。
              </p>
              <p>
                第二十六条 兑奖机构有权查验中奖者的中奖彩票及有效身份证件，兑奖者应予配合。
              </p>
            </div>
          </section>
          <section>
            <a @click="accordion">
              第七章 附则
            </a>
            <div class='content'>
              <p>
                第二十七条 本规则自批准之日起执行。
              </p>
              <p>
                资料来源：<a href="https://www.lottery.gov.cn/bzzx/yxgz/20191119/1002857.html" rel="noopener"
                        target="_blank">中国体彩网</a>
              </p>
            </div>
          </section>

        </article>
        <article v-if="gameRuleIndex==2">
          <section class="active">
            <div class="content">
              <p>
                香港六合彩是从1至49个号码中选出六个为中奖号码的奖券，由香港赛马会的附属公司「香港马会奖券有限公司」经办 。
              </p>
              <p>
                六合彩每星期搅珠二次，通常于星期二及星期五晚上举行，并由电视台现场直播。
              </p>
            </div>
          </section>
          <section>
            <a @click="accordion">
              搅珠结果
            </a>
            <div class="content">
              <p>
                每期搅珠结果均会透过电视、电台、各大报章、香港赛马会网页www.hkjc.com及资讯热线1835 288公布。
              </p>
            </div>

          </section>
          <section>
            <a @click="accordion">
              投注途径
            </a>
            <div class="content">
              <p>
                马场和场外投注处 网上投注服务「投注区」电话投注 马会投注三合一
              </p>
              <p>
                智财咭(ESC)
              </p>
            </div>
          </section>
          <section>
            <a @click="accordion">
              相关规则
            </a>
            <div class="content">


              <p>
                01.特码逢双为特双，特码逢单为特单，49号为和局。
              </p>
              <p>
                02.特码（01~24）为小，特码（25~48）为大，49号为和局。
              </p>
              <p>
                03.两面（指大、小、单、双）。
              </p>
              <p>
                04.六合彩开奖七粒总和（包括特码）。
              </p>
              <p> 
                05.特别号码：六合彩公司当期开出的最后一码为特码。
              </p>
              <p>
                06.特码大小：开出之特码大于或等于25为特码大，小于或等于24为特码小，49为和；特码单双：特码为双数叫特双，如2、18；特码为单数叫特单，如17、33；49为和。
              </p>
              <p>
                07.正码：六合彩公司当期开出之前六个号码叫正码。第一时间出来的叫正码1，依次叫正码2、正码3……正码6，不以大小排序。
              </p>
              <p>
                08.总分大小：所有七个开奖号码的分数总和大于或等于175为总分大；分数总和小于或等于174为总分小。如开奖号码为01、07、15、29、38、46、24，分数总和是160，为总分小。
              </p>
              <p>
                09. 总分单双：所有七个开奖号码的分数总和是单数叫总分单（总单），如分数总和是103、193；分数总和是双数叫总分双（总双），如分数总和是108、160。
              </p>
              <p>
                10.正码1-6特：正1特、正2特、正3特、正4特、正5特、正6特，指下注的正特码与现场滚波开出之正码其开奖顺序及开奖号码相同，视为中奖，例如：现场开奖第三个正码为29号，下注第三个正特码为29则视为中奖，其它号码视为不中奖，其他正特项目依此类推。
              </p>
              <p>
                11.正码1、正码2、正码3、正码4、正码5、正码6的大小单双和特别号码的大小单双规则相同，如正码1为25，则正码1为大，为单；正码2为4，则正码2为小，为双；号码49，则为和。
              </p>
              <p>
                12.特码合数单双：指开出的特码的个位数加上十位数之和为奇数称合数单、之和为偶数称合数双，其中号码49为和。例如：合数单，01为0+1=1，23为2+3=5；合数双，28为2+8=10，39为3+9=12；49为和。
              </p>
              <p>
                13.特码尾大尾小：指开出的特码的末尾数（即个位数）大于或等于5为尾大，如15、39，小于或等于4为尾小，如11、33，其中号码49为和。
              </p>
              <p>
                14.波色：指开出特码所属的颜色。
              </p>

              <table>
                <tr>
                  <td>红色</td>
                  <td>01，02，07，08，12，13，18，19，23，24，29，30，34，35，40，45，46</td>
                </tr>
                <tr>
                  <td>蓝色</td>
                  <td>03，04，09，10，14，15，20，25，26，31，36，37，41，42，47，48</td>
                </tr>
                <tr>
                  <td>绿色</td>
                  <td>05，06，11，16，17，21，22，27，28，32，33，38，39，43，44，49</td>
                </tr>
              </table>
              <p>
                15.生肖中：指开奖七个号码中含有所属生肖的一个或多个号码，但派彩只派一次，即不论同生肖号码出现一个或多个号码都只派彩一次，生肖的号码随年份不同自动变化。例如：2008年为鼠年
              </p>

              <table>
                <tr>
                  <td>鼠</td>
                  <td>01，13，25，37，49</td>
                </tr>
                <tr>
                  <td>牛</td>
                  <td>12，24，36，48</td>
                </tr>
                <tr>
                  <td>虎</td>
                  <td>11，23，35，47</td>
                </tr>
                <tr>
                  <td>兔</td>
                  <td>10，22，34，46</td>
                </tr>
                <tr>
                  <td>龙</td>
                  <td>09，21，33，45</td>
                </tr>
                <tr>
                  <td>蛇</td>
                  <td>08，20，32，44</td>
                </tr>
                <tr>
                  <td>马</td>
                  <td>07，19，31，43</td>
                </tr>
                <tr>
                  <td>羊</td>
                  <td>06，18，30，42</td>
                </tr>
                <tr>
                  <td>猴</td>
                  <td>05，17，29，41</td>
                </tr>
                <tr>
                  <td>鸡</td>
                  <td>04，16，28，40</td>
                </tr>
                <tr>
                  <td>狗</td>
                  <td>03，15，27，39</td>
                </tr>
                <tr>
                  <td>猪</td>
                  <td>02，14，26，38</td>
                </tr>
              </table>
              <p>
                16.生肖不中：指开奖七个号码中都不含有所属生肖的号码，即视为中奖，但派彩只派一次，如下注牛，所属号码为12、24、36、48，开奖号码为1、14、25、33、2、13、35即视为中奖，如七个开奖号码中有12、24、36、48其中一个或几个，均视为不中奖。生肖的号码随年份不同自动变化。例如：2008年为鼠年
              </p>
              <table>
                <tr>
                  <td>鼠</td>
                  <td>01，13，25，37，49</td>
                </tr>
                <tr>
                  <td>牛</td>
                  <td>12，24，36，48</td>
                </tr>
                <tr>
                  <td>虎</td>
                  <td>11，23，35，47</td>
                </tr>
                <tr>
                  <td>兔</td>
                  <td>10，22，34，46</td>
                </tr>
                <tr>
                  <td>龙</td>
                  <td>09，21，33，45</td>
                </tr>
                <tr>
                  <td>蛇</td>
                  <td>08，20，32，44</td>
                </tr>
                <tr>
                  <td>马</td>
                  <td>07，19，31，43</td>
                </tr>
                <tr>
                  <td>羊</td>
                  <td>06，18，30，42</td>
                </tr>
                <tr>
                  <td>猴</td>
                  <td>05，17，29，41</td>
                </tr>
                <tr>
                  <td>鸡</td>
                  <td>04，16，28，40</td>
                </tr>
                <tr>
                  <td>狗</td>
                  <td>03，15，27，39</td>
                </tr>
                <tr>
                  <td>猪</td>
                  <td>02，14，26，38</td>
                </tr>
              </table>
              <p>
                17.尾数：指开奖七个号码中含有所属尾数的一个或多个号码，但派彩只派一次，即不论同尾数号码出现一个或多个号码都只派彩一次。
              </p>
              <table>
                <tr>
                  <td>「1」尾</td>
                  <td>01，11，21，31，41</td>
                </tr>
                <tr>
                  <td>「2」尾</td>
                  <td>02，12，22，32，42</td>
                </tr>
                <tr>
                  <td>「3」尾</td>
                  <td>03，13，23，33，43</td>
                </tr>
                <tr>
                  <td>「4」尾</td>
                  <td> 04，14，24，34，44</td>
                </tr>
                <tr>
                  <td>「5」尾</td>
                  <td>05，15，25，35，45</td>
                </tr>
                <tr>
                  <td>「6」尾</td>
                  <td>06，16，26，36，46</td>
                </tr>
                <tr>
                  <td>「7」尾</td>
                  <td>07，17，27，37，47</td>
                </tr>
                <tr>
                  <td>「8」尾</td>
                  <td>08，18，28，38，48</td>
                </tr>
                <tr>
                  <td>「9」尾</td>
                  <td>09，19，29，39，49</td>
                </tr>
                <tr>
                  <td>「0」尾</td>
                  <td>10，20，30，40</td>
                </tr>
              </table>
              <p>
                例如：开奖结果正码是11、31、42、44、35、32特别号码是21 则「1」尾「2」尾「4」尾「5」尾都为中奖，其它尾数都不中奖。
              </p>

              <p>
                18.连码：
              </p>

              <table>
                <tr>
                  <td>三中二</td>
                  <td>所投注的每三个号码为一组合，若其中二个是开奖号码中的正码，视为中奖，叫三中二；若三个都是开奖号码中的正码，叫三中二之中三，其余情形视为不中奖，
                    如03、04、05为一组合，开奖结果中有03、04两个正码，没有05，叫三中二，按三中二赔付；如开奖中有03、04、05三个正码，叫三中二之中三，按中三赔付；如出现一个
                    或没有，视为不中奖。
                  </td>
                </tr>
                <tr>
                  <td>三全中</td>
                  <td>每三号码为一组合，若三个号码都是开奖号码之正码，视为中奖，其余情形视为不中奖。如03、04、05三个都是开奖号码之正码，视为中奖，如两个正码加上一个
                    特别号码，视为不中奖。
                  </td>
                </tr>
                <tr>
                  <td>二全中</td>
                  <td>每二个号码为一组合，二个号码都是开奖号码之正码，视为中奖，其余情形视为不中奖（含一个正码加一个特别号码之情形）。</td>
                </tr>
                <tr>
                  <td>二中特</td>
                  <td>每二个号码一组合，二个号码都是开奖号码之正码，叫二中特之中二；若其中一个是正码，一个是特别号码，叫二中特之中特；其余情形视为不中奖。</td>
                </tr>
                <tr>
                  <td>二特串</td>
                  <td>每二个号码为一组合，其中一个是正码，一个是特别号码，视为中奖，其余情形视为不中奖（含二个号码都是正码之情形）。</td>
                </tr>
              </table>
              <p>
                19.六肖：
              </p>

              <p>
                挑选六个生肖（排列如同生肖）为一个组合，并选择开奖号码的特码是否在此组合内（49号除外），若选择【中】且开奖号码的特码亦在此组合内，即视为中奖；若选择【不中】且开奖号码的特码亦不在此组合内，即视为中奖；若当期特码开出49号，则所有组合皆视为和局。
              </p>

              <p>
                20.半波：
              </p>

              <p>
                以特码色波和特单，特双，特大，特小为一个投注组合，当期特码开出符合投注组合，即视为中奖；若当期特码开出49号，则视为和局；其余情形视为不中奖。
              </p>

              <p>
                21.过关：
              </p>

              <p>
                任选二至八串为一投注组合，其赔率为所选串的当时赔率的总乘积。投注组合所选串必须全中才视为中奖。当投注组合中某一串号码为49号和时，该组合将该串赔率以1计算；当二串一组合中出现某一串为49和时，该组合将视为和局。单注最高彩金为25万。
              </p>

              <p>
                22.生肖连：（生肖所对应的号码和特码生肖项目的一样；一个生肖对应多个号码，不论同生肖的号码出现一个或多个，派彩只派一次。）
              </p>

              <table>
                <tr>
                  <td>二肖连（中）</td>
                  <td>选择二个生肖为一投注组合进行下注。该注的二个生肖必须在当期开出的七个开奖号码相对应的生肖中，视为中奖。</td>
                </tr>
                <tr>
                  <td>二肖连（不中）</td>
                  <td>选择二个生肖为一投注组合进行下注。该注的二个生肖必须没在当期开出的七个开奖号码相对应的生肖中，视为中奖。</td>
                </tr>
                <tr>
                  <td>三肖连（中）</td>
                  <td>选择三个生肖为一投注组合进行下注。该注的三个生肖必须在当期开出的七个开奖号码相对应的生肖中，视为中奖。</td>
                </tr>
                <tr>
                  <td>三肖连（不中）</td>
                  <td>选择三个生肖为一投注组合进行下注。该注的三个生肖必须没在当期开出的七个开奖号码相对应的生肖中，视为中奖。</td>
                </tr>
                <tr>
                  <td>四肖连（中）</td>
                  <td>选择四个生肖为一投注组合进行下注。该注的四个生肖必须在当期开出的七个开奖号码相对应的生肖中，视为中奖。</td>
                </tr>
                <tr>
                  <td>四肖连（不中）</td>
                  <td>选择四个生肖为一投注组合进行下注。该注的四个生肖必须没在当期开出的七个开奖号码相对应的生肖中，视为中奖。</td>
                </tr>
                <tr>
                  <td>五肖连（中）</td>
                  <td>选择五个生肖为一投注组合进行下注。该注的五个生肖必须在当期开出的七个开奖号码相对应的生肖中，视为中奖。</td>
                </tr>
                <tr>
                  <td>五肖连（不中）</td>
                  <td>选择五个生肖为一投注组合进行下注。该注的五个生肖必须没在当期开出的七个开奖号码相对应的生肖中，视为中奖。</td>
                </tr>
              </table>
              <p>
                23.尾数连：（尾数所对应的号码和尾数项目的一样；一个尾数对应多个号码，不论同尾数的号码出现一个或多个，派彩只派一次。）
              </p>

              <table>
                <tr>
                  <td>二尾连（中）</td>
                  <td>选择二个尾数为一投注组合进行下注。该注的二个尾数必须在当期开出的七个开奖号码相对应的尾数中，视为中奖。</td>
                </tr>
                <tr>
                  <td>二尾连（不中）</td>
                  <td>选择二个尾数为一投注组合进行下注。该注的二个尾数必须没在当期开出的七个开奖号码相对应的尾数中，视为中奖。</td>
                </tr>
                <tr>
                  <td>三尾连（中）</td>
                  <td>选择三个尾数为一投注组合进行下注。该注的三个尾数必须在当期开出的七个开奖号码相对应的尾数中，视为中奖。</td>
                </tr>
                <tr>
                  <td>三尾连（不中）</td>
                  <td>选择三个尾数为一投注组合进行下注。该注的三个尾数必须没在当期开出的七个开奖号码相对应的尾数中，视为中奖。</td>
                </tr>
                <tr>
                  <td>四尾连（中）</td>
                  <td>选择四个尾数为一投注组合进行下注。该注的四个尾数必须在当期开出的七个开奖号码相对应的尾数中，视为中奖。</td>
                </tr>
                <tr>
                  <td>四尾连（不中）</td>
                  <td>选择四个尾数为一投注组合进行下注。该注的四个尾数必须没在当期开出的七个开奖号码相对应的尾数中，视为中奖。</td>
                </tr>
              </table>
              <p>
                24.特肖：当期开出的特码属下注的生肖，即为中奖，否则为不中奖，如2009是牛年，投注特肖【牛】，若开出特码为1，13，25，37，49中的任一个，则中奖，否则不中奖。49不算和。
              </p>

              <p>
                25.不中：
              </p>

              <table>
                <tr>
                  <td>五不中</td>
                  <td>挑选5个号码为一投注组合进行下注。当期开出的七个开奖号码都没有在该下注组合中，即视为中奖。<br>
                    如下注组合为1，2，3，4，5，开奖号码为6，7，8，9，10，11，12，即为中奖，如果开奖号码为5，6，7，8，9，10，11，那么为不中奖。
                  </td>
                </tr>
                <tr>
                  <td>六不中</td>
                  <td>挑选六个号码为一投注组合进行下注。当期开出的七个开奖号码都没有在该下注组合中，即视为中奖。<br>
                    如下注组合为1，2，3，4，5，6，开奖号码为7，8，9，10，11，12，13，即为中奖，如果开奖号码为6，7，8，9，10，11，12，那么为不中奖。
                  </td>
                </tr>
                <tr>
                  <td>七不中</td>
                  <td>挑选七个号码为一投注组合进行下注。当期开出的七个开奖号码都没有在该下注组合中，即视为中奖。<br>
                    如下注组合为1，2，3，4，5，6，7，开奖号码为8，9，10，11，12，13，14，即为中奖，如果开奖号码为7，8，9，10，11，12，13，那么为不中奖。
                  </td>
                </tr>
                <tr>
                  <td>八不中</td>
                  <td>挑选八个号码为一投注组合进行下注。当期开出的七个开奖号码都没有在该下注组合中，即视为中奖。<br>
                    如下注组合为1，2，3，4，5，6，7，8，开奖号码为9，10，11，12，13，14，15，即为中奖，如果开奖号码为8，9，10，11，12，13，14，那么为不中奖。
                  </td>
                </tr>
                <tr>
                  <td>九不中</td>
                  <td>挑选九个号码为一投注组合进行下注。当期开出的七个开奖号码都没有在该下注组合中，即视为中奖。<br>
                    如下注组合为1，2，3，4，5，6，7，8，8，9，开奖号码为10，11，12，13，14，15，16，即为中奖，如果开奖号码为9，10，11，12，13，14，15，那么为不中奖。
                  </td>
                </tr>
                <tr>
                  <td>十不中</td>
                  <td>挑选十个号码为一投注组合进行下注。当期开出的七个开奖号码都没有在该下注组合中，即视为中奖。<br>
                    如下注组合为1，2，3，4，5，6，7，8，9，10，开奖号码为11，12，13，14，15，16，17，即为中奖，如果开奖号码为10，11，12，13，14，15，16，那么为不中奖。
                  </td>
                </tr>
                <tr>
                  <td>十一不中</td>
                  <td>挑选十一个号码为一投注组合进行下注。当期开出的七个开奖号码都没有在该下注组合中，即视为中奖。<br>
                    如下注组合为1，2，3，4，5，6，7，8，9，10，11，开奖号码为12，13，14，15，16，17，18即为中奖，如果开奖号码为11，12，13，14，15，16，17，那么为不中奖。
                  </td>
                </tr>
                <tr>
                  <td>十二不中</td>
                  <td>挑选十二个号码为一投注组合进行下注。当期开出的七个开奖号码都没有在该下注组合中，即视为中奖。<br>
                    如下注组合为1，2，3，4，5，6，7，8，9，10，11，12，开奖号码为13，14，15，16，17，18，19即为中奖，如果开奖号码为12，13，14，15，16，17，18，那么为不中奖。
                  </td>
                </tr>
              </table>
              <p>
                26.多选中一：每个号码都有自己的赔率，下注组合的总赔率，取该组合号码的最低赔率为总赔率。
              </p>

              <table>
                <tr>
                  <td>五中一</td>
                  <td>挑选五个号码为一投注组合进行下注。当期开出的七个开奖号码只有一个号码在该下注组合中，即视为中奖；其余情形视为不中奖。<br>
                    例如：没有一个号码在组合中，或者是有两个及两个以上号码在组合中，都视为不中奖。
                  </td>
                </tr>
                <tr>
                  <td>六中一</td>
                  <td>挑选六个号码为一投注组合进行下注。当期开出的七个开奖号码只有一个号码在该下注组合中，即视为中奖；其余情形视为不中奖。<br>
                    例如：没有一个号码在组合中，或者是有两个及两个以上号码在组合中，都视为不中奖。
                  </td>
                </tr>
                <tr>
                  <td>七中一</td>
                  <td>挑选七个号码为一投注组合进行下注。当期开出的七个开奖号码只有一个号码在该下注组合中，即视为中奖；其余情形视为不中奖。<br>
                    例如：没有一个号码在组合中，或者是有两个及两个以上号码在组合中，都视为不中奖。
                  </td>
                </tr>
                <tr>
                  <td>八中一</td>
                  <td>挑选八个号码为一投注组合进行下注。当期开出的七个开奖号码只有一个号码在该下注组合中，即视为中奖；其余情形视为不中奖。<br>
                    例如：没有一个号码在组合中，或者是有两个及两个以上号码在组合中，都视为不中奖。
                  </td>
                </tr>
                <tr>
                  <td>九中一</td>
                  <td>挑选九个号码为一投注组合进行下注。当期开出的七个开奖号码只有一个号码在该下注组合中，即视为中奖；其余情形视为不中奖。<br>
                    例如：没有一个号码在组合中，或者是有两个及两个以上号码在组合中，都视为不中奖。
                  </td>
                </tr>
                <tr>
                  <td>十中一</td>
                  <td>挑选十个号码为一投注组合进行下注。当期开出的七个开奖号码只有一个号码在该下注组合中，即视为中奖；其余情形视为不中奖。<br>
                    例如：没有一个号码在组合中，或者是有两个及两个以上号码在组合中，都视为不中奖。
                  </td>
                </tr>
              </table>
              <p>
                27.特平中：每个号码都有自己的赔率，下注组合的总赔率，取该组合号码的最低赔率为总赔率。
              </p>

              <table>
                <tr>
                  <td>一粒任中</td>
                  <td>挑选一个号码为一投注组合进行下注。当期开出的七个开奖号码有任何一个号码在该下注组合中，即视为中奖；其余情形视为不中奖。</td>
                </tr>
                <tr>
                  <td>二粒任中</td>
                  <td>挑选二个号码为一投注组合进行下注。当期开出的七个开奖号码有任何一个号码在该下注组合中，即视为中奖；其余情形视为不中奖。</td>
                </tr>
                <tr>
                  <td>三粒任中</td>
                  <td>挑选三个号码为一投注组合进行下注。当期开出的七个开奖号码有任何一个号码在该下注组合中，即视为中奖；其余情形视为不中奖。</td>
                </tr>
                <tr>
                  <td>四粒任中</td>
                  <td>挑选四个号码为一投注组合进行下注。当期开出的七个开奖号码有任何一个号码在该下注组合中，即视为中奖；其余情形视为不中奖。</td>
                </tr>
                <tr>
                  <td>五粒任中</td>
                  <td>挑选五个号码为一投注组合进行下注。当期开出的七个开奖号码有任何一个号码在该下注组合中，即视为中奖；其余情形视为不中奖。</td>
                </tr>
              </table>
              <p>
                28.合肖：挑选二至五个生肖（排列如同生肖）为一个组合，并选择开奖号码的特码是否在此组合内，若选择【中】且开奖号码的特码亦在此组合内，则视为中奖。若选择【不中】且开奖号码的特码亦不在此组合内，即视为中奖。若当期特码开出49号，则视为和局；其余情形视为不中奖。
              </p>

              <p>
                29.七码：估猜七个开奖号码中【单、双、大、小】各有多少个，例如︰投注【单0】，若该期开出的七个号码中一个单数号码都没出现，即视为中奖，若七个号码中有一个或多个单数出现则为不中奖，开出49号算【单、大】。
              </p>

              <p>
                30.五行：指开出特码所属的五行号码(属性会根据每年的农历年而变更)。
              </p>

              <table>
                <tr>
                  <td>金</td>
                  <td>07,08,21,22,29,30,37,38</td>
                </tr>
                <tr>
                  <td>木</td>
                  <td>03,04,11,12,19,20,33,34,41,42,49</td>
                </tr>
                <tr>
                  <td>水</td>
                  <td>09,10,17,18,25,26,39,40,47,48</td>
                </tr>
                <tr>
                  <td>火</td>
                  <td>05,06,13,14,27,28,35,36,43,44</td>
                </tr>
                <tr>
                  <td>土</td>
                  <td>01,02,15,16,23,24,31,32,45,46</td>
                </tr>
              </table>
              <p>
                31.一肖量：估猜开出的七个开奖号码中所属的不同生肖的总个数，例如︰投注【肖5】，若该期开出的七个号码中所属的不同生肖总个数为5，即视为中奖，若七个号码中所属的不同生肖的总个数大于5或小于5则为不中奖。
              </p>

              <p>
                32.尾数量：估猜开出的七个开奖号码中所对应的不同尾数的总个数，例如︰投注【尾5】，若该期开出的七个号码的不同尾数总个数为5，即视为中奖，若七个号码中的不同尾数的总个数大于5或小于5则为不中奖。
              </p>

              <p>
                33.尾数不中：指开出的七个开奖号码中不含有投注所属尾数，即视为中奖。
              </p>

              <p>
                34.七色波：以开出的7个色波，那种颜色最多为中奖。 开出的6个正码各以1个色波计，特别号以1.5个色波计。而以下3种结果视为和局。
              </p>

              <table>
                <tr>
                  <td>(1)6个正码开出3蓝3绿，而特别码是1.5红</td>
                </tr>
                <tr>
                  <td>(2)6个正码开出3蓝3红，而特别码是1.5绿</td>
                </tr>
                <tr>
                  <td>(3)6个正码开出3绿3红，而特别码是1.5蓝</td>
                </tr>
              </table>
              <p>如果出现和局，所有投注红，绿，蓝七色波的金额将全数退回，会员也可投注和局</p>
              <p>
                35.特码合数大小
              </p>

              <table>
                <tr>
                  <td>
                    特合大
                  </td>
                  <td>
                    以特码的个位和十位数字之和来判定胜负，介于7-12为合大。例如：07、34、48。
                  </td>
                </tr>
                <tr>
                  <td>特合小</td>
                  <td>以特码的个位和十位数字之和来判定胜负，介于1-6为合小。例如：01、24、33。</td>
                </tr>
                <tr>
                  <td>和局</td>
                  <td>特码为49时</td>
                </tr>


              </table>

              <a @click="accordion">
                下注模式說明
              </a>

              <p>
                1. 拖頭：
              </p>
              <p>
                是一種快速下注模式,即選擇好固定號碼（生肖、尾數）為頭（或稱為膽），再選擇其他號碼（生肖、尾數）與其組合進行下注。如三中二之8、18拖19、20、21，即是三組：8-18-19、8-18-20、8-18-21；如二肖連之鼠拖牛、虎，即是2組：鼠-牛、鼠-虎；如二尾連之1拖2、3，即是2組：1-2、1-3。[應用于連碼、生肖連、尾數連等項目]。
              </p>
              <p>
                2. 複式：
              </p>
              <p>
                是一種快速下注模式，即選擇多個號碼（生肖、尾數）進行排列組合進行下注。[應用于連碼、五不中、生肖連、尾數連等項目]。
              </p>
              <p>
                3. 多組投注：
              </p>
              <p>
                是一種快速下注模式，可以連續選擇多組號碼進行下注。[應用于五不中等項目]。
              </p>
              <p>
                4. 生肖對碰：
              </p>
              <p>
                是一種簡易下注模式，生肖都相對應號碼，選擇兩個生肖對碰下注，相應是選擇多個號碼組合成不重復組合進行下注。[應用于連碼等項目]。
              </p>
              <p>
                5. 尾數對碰：
              </p>
              <p>
                是一種簡易下注模式，尾數都相對應號碼，選擇兩個尾數對碰下注，相應是選擇多個號碼組合成不重復組合進行下注。[應用于連碼等項目]。
              </p>
              <p>
                6. 生肖尾數對碰：
              </p>
              <p>
                是一種簡易下注模式，生肖和尾數都相對應號碼，選擇生肖尾數對碰下注，相應是選擇多個號碼組合成不重復組合進行下注。[應用于連碼等項目]。
              </p>
              <p>
                资料来源：<a href="https://bet.hkjc.com/marksix/index.aspx?lang=ch" target="_blank">香港六合彩官网</a>
              </p>
            </div>
          </section>
        </article>
        <article v-if="gameRuleIndex==3">
          <section class="active">
            <a @click="accordion">
              第一章 总则
            </a>
            <div class='content'>
              <p>
                第一条 根据财政部《彩票发行与销售管理暂行规定》制定本游戏规则。
              </p>
              <p>
                第二条 排列3电脑体育彩票由国家体育总局体育彩票管理中心(以下简称“中体彩中心”)统一发行。经中体彩中心授权，各省、自治区、直辖市体育彩票管理中心(以下简称“省级体彩中心”)在所辖区域内承销排列3。
              </p>
              <p>
                第三条 “排列3”实行自愿购买，凡购买该彩票者即被视为同意并遵守本规则。
              </p>
            </div>
          </section>
          <section>
            <a @click="accordion">
              第二章 游戏方法
            </a>
            <div class='content'>
              <p>
                第四条 排列3是指从000-999的数字中选取1个3位数作为一注投注号码进行的投注。每注金额人民币2元。
              </p>
              <p>
                第五条 排列3投注方式分为直选投注和组选投注。
              </p>
              <p>
                (一)直选投注：所选3位数以唯一排列方式作为一注的投注。
              </p>
              <p>
                (二)组选投注：所选3位数以所有排列方式作为一注的投注。具体分为：
              </p>
              <p>
                组选6：如果一注组选投注的3位数中每位数字各不相同，则有6种不同的排列方式，有6次中奖机会；
              </p>
              <p>
                组选3：如果一注组选投注的3位数中有2位数字相同，则有3种不同的排列方式，有3次中奖机会。
              </p>
              <p>
                第六条 购买者可对其选定的投注号码进行多倍投注，投注倍数范围为 2-99倍。单张彩票的投注金额最高不得超过20000元。
              </p>
              <p>
                第七条 排列3每天销售一期，期号以开奖日界定，按日历年度编排。
              </p>
              <p>
                第八条 购买者可在各省体彩机构设置的销售网点投注。投注号码经投注机打印出的对奖凭证，交购买者保存，此对奖凭证即为排列3彩票。
              </p>
              <p>
                第九条 投注者可选择机选号码投注、自选号码投注。机选号码投注是指由投注机随机产生投注号码进行投注，自选号码投注是指将购买者选定的号码输入投注机进行投注。
              </p>
              <p>
                第十条 排列3对每期全部投注号码的可投注数量实行限量销售，若投注号码受限，则不能投注。若因销售终端故障、通讯线路故障和投注站信用额度受限等原因造成投注不成功，应退还购买者投注金额。
              </p>
            </div>
          </section>
          <section>
            <a @click="accordion">
              第三章 设奖
            </a>
            <div class='content'>
              <p>
                第十一条 排列3按当期销售总额的53%、13%、34%分别计提彩票奖金、彩票发行费和彩票公益金。彩票奖金分为当期奖金和调节基金，其中，52%为当期奖金，1%为调节基金。
              </p>

              <p>
                第十二条 排列3按不同投注方式设奖，均为固定奖。奖金规定如下：
              </p>
              <p>
                (一)直选投注：单注奖金固定为1040元。
              </p>
              <p>
                (二)组选投注：
              </p>
              <p>
                组选6：单注奖金固定为173元；
              </p>
              <p>
                组选3：单注奖金固定为346元。
              </p>
              <p>
                第十三条 排列3设置调节基金。调节基金包括按销售总额1%的提取部分、逾期未退票的票款。调节基金专项用于支付不可预见情况下的奖金支出风险，以及设立特别奖。动用调节基金设立特别奖，应报同级财政部门审核批准。
              </p>
              <p>
                第十四条
                排列3设置奖池，奖池资金由计提当期奖金与实际中出奖金的差额组成。当期实际中出奖金小于计提当期奖金时，余额进入奖池；当期实际中出奖金超过计提当期奖金时，差额由奖池资金补足；当奖池资金不足时，由调节基金补足，调节基金不足时，用彩票兑奖周转金垫支。在出现彩票兑奖周转金垫支情况下，当调节基金有资金滚入时优先偿还垫支的彩票兑奖周转金。
              </p>
            </div>
          </section>
          <section>
            <a @click="accordion">
              第四章 开奖及公告
            </a>
            <div class='content'>
              <p>
                第十五条 排列3每天开奖一次。以中国体育彩票排列5当期开奖号码的前三位号码作为排列3当期开奖号码。
              </p>
              <p>
                第十六条 每期开奖后，由各省体彩机构向社会公布当期销售总额、开奖号码、各奖级中奖情况以及奖池资金余额等信息，并将开奖结果通知各销售网点。
              </p>
            </div>
          </section>
          <section>
            <a @click="accordion">
              第五章 中奖
            </a>
            <div class='content'>
              <p>
                第十七条 排列3根据投注号码与开奖号码相符情况确定相应中奖资格。具体规定如下：
              </p>
              <p>
                (一)直选投注：投注号码与开奖号码数字相同且顺序一致，即中奖。例如，开奖号码为123，则直选投注号码为123即中奖。
              </p>
              <p>
                (二)组选投注：
              </p>
              <p>
                组选6：开奖号码中每位数字均不相同，投注号码与开奖号码数字相同且顺序不限，即中奖。例如，组选6投注号码为123，则开奖号码为123、132、213、231、312、321之一均中奖。
              </p>
              <p>
                组选3：开奖号码中任意2位数字相同，投注号码与开奖号码数字相同且顺序不限，即中奖。例如，组选3投注号码为122，则开奖号码为122、212、221之一均中奖。
              </p>
            </div>
          </section>
          <section>
            <a @click="accordion">
              第六章 兑 奖
            </a>
            <div class='content'>
              <p>
                第十八条 排列3兑奖当期有效。中奖者应当自开奖之日起60个自然日内，持中奖彩票到指定的地点兑奖。逾期未兑奖视为弃奖，弃奖奖金纳入彩票公益金。
              </p>
              <p>
                第十九条 中奖彩票为兑奖唯一凭证，中奖彩票因玷污、损坏等原因不能正确识别的，不能兑奖。
              </p>
              <p>
                第二十条 兑奖机构有权查验中奖者的中奖彩票及有效身份证件，兑奖者应予配合。
              </p>
              <a @click="accordion">
                第七章 附 则
              </a>
              <p>
                第二十一条 本规则自批准之日起执行。
              </p>
              <p>
                资料来源：<a href="https://www.lottery.gov.cn/bzzx/yxgz/20191119/1002855.html" target="_blank">中国体彩网</a>
              </p>
            </div>
          </section>
        </article>
        <article v-if="gameRuleIndex==5">
          <section class="active">
            <a @click="accordion">
              第一章 总则
            </a>
            <div class='content'>
              <p>
                第一条 根据财政部《彩票发行与销售管理暂行规定》和国家体育总局《体育彩票发行与销售管理暂行办法》以及《计算机销售体育彩票管理暂行办法》，制定本游戏规则。
              </p>
              <p>
                第二条 排列5电脑体育彩票由国家体育总局体育彩票管理中心统一发行，在全国范围内采用计算机网络系统进行联合销售。
              </p>
              <p>
                第三条 “排列5”实行自愿购买，凡购买该彩票者即被视为同意并遵守本规则。
              </p>
            </div>
          </section>
          <section>
            <a @click="accordion">
              第二章 游戏方法
            </a>
            <div class='content'>
              <p>
                第四条 购买“排列5”时，由购买者从00000-99999的数字中选取1个5位数为投注号码进行投注。
              </p>
              <p>
                第五条
                购买者可在全国各省（区、市）体育彩票管理中心设置的投注站进行投注。投注号码可由投注机随机产生，也可通过投注单将购买者选定的号码输入投注机确定。投注号码经系统确认后打印出的兑奖凭证即为“排列5”电脑体育彩票，交购买者保存。
              </p>
              <p>
                第六条 “排列5”每注2元人民币。彩票不记名、不挂失，不返还本金，不流通使用。
              </p>
            </div>
          </section>
          <section>
            <a @click="accordion">
              第三章 设奖
            </a>
            <div class='content'>
              <p>
                第七条 “排列5”设1个奖级，为固定奖。
              </p>
              <p>
                第八条 奖金分配：
              </p>
              <p>
                一等奖，单注固定奖金100000元。
              </p>
            </div>
          </section>
          <section>
            <a @click="accordion">
              第四章 奖金管理
            </a>
            <div class='content'>
              <p>
                第九条 “排列5”按每期销售总额的50％计提奖金,计提奖金分为当期奖金和调节基金，其中49%为当期奖金，1%为调节基金。
              </p>
              <p>
                第十条
                “排列5”设置奖池，奖池由当期奖金与实际中出奖金的差额累计而成。若当期奖金大于当期中出奖金时，余额滚入奖池；若当期奖金小于当期中出奖金时，差额用奖池补足；若奖池不足时，用调节基金补足，调节基金不足时，从发行经费中垫支。
              </p>
              <p>
                第十一条 调节基金还包括弃奖收入和逾期未退票的票款。调节基金专项用于支付各种不可预见情况下的奖金支出风险以及设立特别奖。
              </p>
            </div>
          </section>
          <section>
            <a @click="accordion">
              第五章 中奖
            </a>
            <div class='content'>
              <p>
                第十二条 所购彩票与开奖结果对照，符合以下情况即为中奖。
              </p>
              <p>
                一等奖，所选号码与中奖号码全部相同且顺序一致。例如：中奖号码为43751，则排列5的中奖结果为：43751。
              </p>
            </div>
          </section>
          <section>
            <a @click="accordion">
              第六章 开奖及公告
            </a>
            <div class='content'>
              <p>
                第十三条 “排列5”每天开奖一次，摇奖过程在公证人员监督下进行，通过电视台播出。
              </p>
              <p>
                第十四条 “排列5”单独摇奖，其中奖号码为全国联网电脑体育彩票排列5当期摇出的全部中奖号码。
              </p>
              <p>
                第十五条 每期开奖后，省级体彩中心将当期销售总额、开奖号码、各奖等中奖注数、奖额以及奖池资金余额，通过新闻媒体向社会公布。
              </p>
            </div>
          </section>
          <section>
            <a @click="accordion">
              第七章 兑 奖
            </a>
            <div class='content'>
              <p>
                第十六条 “排列5”兑奖当期有效。每期自开奖之日起60个自然日内为兑奖期，逾期未兑，视为弃奖纳入调节基金。
              </p>
              <p>
                第十七条 兑奖机构有权查验中奖者的中奖彩票及有效身份证件，兑奖者应予配合。
              </p>
              <p>
                第十八条 凡伪造、涂改中奖彩票，冒领奖金者，送交司法机关追究法律责任。
              </p>
            </div>
          </section>
          <section>
            <a @click="accordion">
              第八章 附 则
            </a>
            <div class='content'>
              <p>
                第十九条 本游戏规则解释权属国家体育总局体育彩票管理中心。
              </p>
              <p>
                第二十条 本游戏规则自下发之日起执行。
              </p>
              <p>
                资料来源：<a href="https://www.lottery.gov.cn/bzzx/yxgz/20191119/1002854.html" target="_blank"
                        rel="noopener">中国体彩网</a>
              </p>
            </div>
          </section>
        </article>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'GameRule',
  data() {
    return {
      gameRuleIndex: 0,
    }
  },
  computed: {},
  methods: {
    changeRule(i) {
      this.gameRuleIndex = i;
    },
    accordion(evt) {
      if (evt.target.parentNode.classList.contains('active')) {
        evt.target.parentNode.classList.remove('active')
      } else {
        evt.target.parentNode.classList.add('active')
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
.game_rule{
  padding-bottom: 62px;
}
.content {
  padding: 0;
  overflow: hidden;
}

.content .text_box {
  padding: 55px 0 0;
  box-shadow: none;
}

.content .text_box .option {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 4px auto 0;
  padding: 0 15px 8px;
  border-bottom: 1px solid #dbdada;
}

.content .text_box .option .lottery {
  padding: 5px 10px;
  margin: 0 5px;
  border-radius:12px ;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  color: #727272;
  font-weight: bold;
}

.content .text_box .option .lottery.checked {
  border: 1px solid #0f2957;
  border-radius:12px ;
  background: #0f2957;
  color: #fff;
}

.rule {
  padding: 15px;
}

table {
  margin: 15px auto;

  td {
    padding: 5px;
    border: 1px solid #d3d3d3;
    text-align: left;
  }
}
article{
  text-align: left;
}

section {
  border-bottom: 2px solid #f6f5f5;

  > a {
    font-size: 14px;
    color: #3d3d3d;
    padding: 12px 15px;
    display: block;
    font-weight: bold;
    position: relative;
    background-color: #fff;

    &:after {
      background: url('~@/img/H5/arrow.png') no-repeat center;
      width: 20px;
      height: 20px;
      content: '';
      display: block;
      position: absolute;
      right: 10px;
      top: 10px;
      transition: transform .15s ease-out;
    }
  }

  &.active > a:after {
    transform: rotate(180deg)
  }

  p, td {
    min-width: 80px;
    font-size: 10px !important;
    line-height: 2 !important;
    margin-bottom: 8px;
    color: #7d7d7d;
  }

  .content {
    display: none;
    background: #f6f5f5;
    padding: 15px;
    font-size: 10px;
  }

  &.active .content {
    display: block;
  }
}

</style>